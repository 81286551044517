.heroJedi{
position: absolute;

object-fit: cover;
/* object-position: center center; */
width: 100%;
height: 100%;
opacity: 0.1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.typing-demo {
  width: 12ch;
  animation: typing 1s steps(12);
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 3px solid; */
  font-family: monospace;
  font-size: 2em;
}
@media only screen and (max-width: 600px) {
.typing-demo {
  animation: typing 3s steps(12);
}
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}